import { Link } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import './CookiePolicy.scss'

const CookiePolicy = (): any => {
  const [showCookiePolicy, setShowCookiePolicy] = useState<boolean>(true)

  const getExpiry = useCallback(() => {
    if (localStorage.getItem('consentExpiry')) {
      return localStorage.getItem('consentExpiry')
    }
    return '0'
  }, [])

  useEffect(() => {
    if (localStorage.getItem('consent') === 'true') {
      setShowCookiePolicy(false)
    }
  }, [showCookiePolicy])

  useEffect(() => {
    const expire = getExpiry()
    if (new Date().getTime() > Number(expire || '0')) {
      localStorage.clear()
      setShowCookiePolicy(true)
    }
  }, [getExpiry])

  useEffect(() => {
    window.addEventListener('click', () => {
      const expiry = new Date().getTime() + 300000 // 5 minutes
      localStorage.setItem('consentExpiry', expiry.toString())
    })
  }, [])

  const acceptCookie = (): void => {
    setShowCookiePolicy(false)
    localStorage.setItem('consent', 'true')
  }

  return (
    <>
      {showCookiePolicy && (
        <div className="cookie">
          <div className="cookie__overlay" />
          <div className="cookie__content">
            <div className="cookie__description">
              <p>
                This website uses cookies to enable all functionalities for the
                best performance during your visit. Should you wish to decline
                cookies to be sent to you, kindly adjust your browser settings
                accordingly. By continuing to browse this website, you are
                giving consent to the use of cookies on this website. See
                updated{' '}
                <Link to="/privacy-notice/" className="cookie__link">
                  Privacy Notice
                </Link>{' '}
                for more details.
              </p>
            </div>
            <div className="cookie__btn-wrapper">
              <button
                type="button"
                className="cookie__accept-btn"
                onClick={acceptCookie}
              >
                Accept &amp; Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default CookiePolicy
