"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toKebabCase = exports.toCamelCase = void 0;
var toCamelCase = function (str) {
    return (str === null || str === void 0 ? void 0 : str.toLowerCase().replace(/[()]/g, '').replace(/([-_ ][a-z0-9])/g, function (group) {
        return group.toUpperCase().replace(/[-_ ]/g, '');
    })) || '';
};
exports.toCamelCase = toCamelCase;
var toKebabCase = function (str) {
    return (str === null || str === void 0 ? void 0 : str.toLowerCase().replace(/([ _])/g, '-')) || '';
};
exports.toKebabCase = toKebabCase;
