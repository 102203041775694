import React, { ReactElement } from 'react'
import './FooterLinkNavigation.scss'
import { Link } from 'gatsby'

import navItems from '../../data/footer-mobile-navigation.json'

const FooterLinkNavigation = (): ReactElement => {
  return (
    <div className="footer-link">
      <div className="footer-link-container">
        <div className="footer-link-group">
          {navItems.slice(0, 4).map((navItem) => {
            return (
              <div key={navItem.name}>
                <Link to={`${navItem.url}`}>{navItem.name}</Link>
              </div>
            )
          })}
        </div>
        <div className="footer-link-group">
          {navItems.slice(4, 8).map((navItem) => {
            return (
              <div key={navItem.name}>
                <Link to={`${navItem.url}`}>{navItem.name}</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FooterLinkNavigation
