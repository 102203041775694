import React, { ReactElement, memo } from 'react'
import Header, {
  HeaderPropTypes,
  SearchOptions,
} from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import './Layout.scss'

type LayoutPropTypes = {
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
  includeNewsLetter?: boolean
}

const Layout = ({
  children,
  isStick,
  setStick,
  navButtonVariant = 'burger',
  returnPageUrl,
  includeNewsLetter,
}: LayoutPropTypes & HeaderPropTypes & SearchOptions): ReactElement => {
  return (
    <>
      <Header
        isStick={isStick}
        setStick={setStick}
        navButtonVariant={navButtonVariant}
        returnPageUrl={returnPageUrl}
      />
      <div id="main">{children}</div>
      <Footer includeNewsLetter={includeNewsLetter} />
    </>
  )
}

export default memo(Layout)
